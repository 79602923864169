<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Territories</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/setup/new-territory" color="primary" text>
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row dense>
          <v-col v-if="territories.length > 0" cols="12" sm="12" md="12">
            <v-treeview
              color="accent"
              :activatable="trueValue"
              :hoverable="trueValue"
              open-all
              return-object
              item-text="descript"
              item-key="id"
              :items="territories"
              item-children="children_recursive"
              :search="search"
            >
              <template v-slot:label="{ item }">
                <v-btn text color="primary" :to="`/territory/${item.id}`">{{
                  item.descript
                }}</v-btn>
              </template>
            </v-treeview>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  computed: {},
  data: () => ({
    search: null,
    descript: null,
    trueValue: true,
    loader: false,
    dialog: false,
    selectionType: "leaf",
    activeTerritory: {},
    territories: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "descript", value: "descript" },
    ],
    items: [
      {
        id: 1,
        descript: "Nairobi",
        parent: null,
        index: null,
        inactive: "N",
        created_at: "2020-11-16T12:51:17.000000Z",
        updated_at: "2020-11-16T12:51:17.000000Z",
        children_recursive: [
          {
            id: 4,
            descript: "kasarani",
            parent: 1,
            index: null,
            inactive: "N",
            created_at: "2020-12-11T11:55:25.000000Z",
            updated_at: "2020-12-11T11:55:25.000000Z",
            children_recursive: [],
          },
        ],
      },
      {
        id: 2,
        descript: "Thika",
        parent: null,
        index: null,
        inactive: "N",
        created_at: "2020-11-16T12:51:22.000000Z",
        updated_at: "2020-11-16T12:51:22.000000Z",
        children_recursive: [
          {
            id: 3,
            descript: "Makongeni",
            parent: 2,
            index: null,
            inactive: "N",
            created_at: "2020-12-11T11:54:20.000000Z",
            updated_at: "2020-12-11T11:54:20.000000Z",
            children_recursive: [],
          },
        ],
      },
    ],
  }),
  methods: {
    selectItem(data) {
      this.activeTerritory = data;
      this.dialog = true;
    },
    getTerritories() {
      const self = this;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData.territories;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTerritories();
  },
};
</script>
